<script setup lang="ts">
const emit = defineEmits(['update:modelValue']);

const props = withDefaults(
  defineProps<{
    title?: string;
    showHeader?: boolean;
    showControls?: boolean;
    withSearch?: boolean;
    preventClose?: boolean;
    modelValue?: boolean;
    width?: string;
    side?: 'right' | 'left';
  }>(),
  {
    title: 'Modal title',
    showHeader: true,
    showControls: true,
    withSearch: false,
    preventClose: false,
    modelValue: false,
    width: 'max-w-[35vw] xl:max-w-[48vw]',
    side: 'right',
  }
);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <USlideover
    v-model="value"
    :prevent-close="props.preventClose"
    :side="props.side"
    :ui="{
      wrapper: 'p-3 md:p-0',
      container: 'flex flex-col items-center justify-start',
      base: `h-full overflow-hidden border border-ht-border-2 rounded-[20px] self-center flex-col justify-start items-start inline-flex md:h-screen md:rounded-none`,
      overlay: {
        background: 'bg-ht-overlay-bg-fill-2 dark:bg-ht-overlay-bg-fill-2',
      },
      margin: 'sm:m-0',
      width: `${props.width} md:max-w-fit`,
      background: 'bg-ht-primary-bg-fill dark:bg-ht-primary-bg-fill',
      shadow: 'shadow-2xl dark:shadow-2xl',
    }"
  >
    <div
      v-if="props.showHeader"
      class="w-full h-[50px] pl-6 pr-[18px] py-2 bg-ht-primary-bg-fill-90 border-b border-ht-border-1 justify-between items-center inline-flex"
      :class="{ 'h-[60px]': props.withSearch }"
    >
      <span
        v-if="!props.withSearch"
        class="text-ht-monochrome-bw text-sm font-medium leading-[1.2]"
      >
        {{ props.title }}
      </span>

      <div
        v-else
        class="w-full h-full justify-start items-center gap-4 inline-flex"
      >
        <HIcon icon="Search" class="w-4 h-4" />
        <span class="text-ht-text-gray-1 text-opacity-50 text-sm font-medium">
          Search clients
        </span>
      </div>

      <slot name="headerActions">
        <HButton
          icon-right="Cancel"
          variant="plain"
          class="w-[34px] h-[34px] px-1"
          @click="emit('update:modelValue', false)"
        />
      </slot>
    </div>

    <div class="w-full h-full overflow-y-scroll">
      <slot />
    </div>

    <div
      v-if="props.showControls"
      class="absolute bottom-0 z-50 w-full px-[18px] py-2 bg-ht-primary-bg-fill-90 border-t border-ht-border-1 justify-end items-center gap-2 inline-flex"
    >
      <slot name="actions" />
    </div>
  </USlideover>
</template>
